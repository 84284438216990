<template>
	<div class="half_layer_wrap">
		<div class="half_layer_wrap_inner">
			<article class="half_view">
				<div class="layer_title">
					<div class="tit_tab">
						<h3 class="focus">{{  $language.common.futures }}</h3>
						<h3
							v-if="is_dual"
							@click="$emit('to', {name: 'mafia022'})"
						>{{  $language.common.transfer_history }}</h3>
					</div>
				</div>
				<div class="layer_table">
					<table class="nft_info_table">
						<colgroup>
							<col style="width: 35%">
							<col style="width: 40%">
							<col style="width: 25%">
						</colgroup>
						<thead>
						<tr>
							<th class="t-left">{{  $language.common.futures_time }}</th>
							<th class="t-left">FROM/TO</th>
							<th class="t-right">{{  $language.common.status }}</th>
						</tr>
						</thead>
						<tbody>
						<template
							v-if="items.length > 0"
							>
						<tr
							v-for="(item, index) in items"
							:key="'item_' + index"
						>
							<td>{{ item.gift_daytime }}</td>
							<td>
								<p>{{ item.from_nickname }}</p>
								<p>{{ item.to_nickname }}</p>
							</td>
							<td class="tb_ing_gray">{{  item.state_name }}</td>
						</tr>
						</template>
						<tr
							v-else
						>
							<td colspan="3" class="td_list_none">
								<div class="list_none">
									<img :src="require('@/assets/image/list_none.png')" alt="Not Found History">
									<span>{{  $language.common.Not_found_history }}</span>
								</div>
							</td>
						</tr>
						</tbody>
					</table>
				</div>
			</article>
			<button class="btn_close" @click="$emit('click')">
				<i class="icon icon-close"></i>
			</button>
		</div>
		<div class="bg_overlay"></div>
	</div>
</template>

<script>
	export default {
		name: 'mafia021'
		, props: ['is_dual', 'user', 'card']
		, data: function(){
			return {
				program: {
					name: this.$language.common.futures
				}
				, nft_id: this.$route.params.id
				, items: []
				, item_search: {
					page: this.$language.base.page_number
					, list_cnt: this.$language.base.pagerecnum
				}
			}
		}
		,methods: {
			getData: async function(){
				try{
					this.$bus.$emit('on', true)
					const result = await this.$Request({
						method: 'post'
						, url: this.$api_url.api_path.get_present_history
						, data: {
							member_number: this.user.member_number
							, nft_card_number: this.card.nft_card_number
							, page_number: this.item_search.page
							, pagerecnum: this.item_search.list_cnt
						}
					})
					if(result.success){
						this.items = result.data.nft_card_gift_transaction_list
					}else{
						this.$bus.$emit('notify',  { type: 'error', messages: result.message})
					}
				}catch (e) {
					console.log(e)
				}finally {
					this.$bus.$emit('on', false)
				}
			}
		}
		,created() {
			this.$emit('onLoad', this.program)

			if(this.is_dual){
//
			}else{
				this.getData()
			}
		}
	}
</script>