<template>
	<div class="full_layer_wrap">
		<div class="full_layer_container">
			<div class="full_layer_wrap_inner">
				<article class="full_view">
					<div class="nft_card nft_qr">
						<p class="qr_tit">UTILITIES CARD</p>
					</div>

					<div
						v-if="is_use"
						class="view_cont_wrap"
					>
						<p class="pop_desc">
              {{  $language.common.cancellation_utility_card_question }}
							<span>{{  $language.common.cancellation_utility_card_question_02 }}</span>
						</p>
						<slot name="content"></slot>
					</div>

					<div
						v-else
						class="view_cont_wrap"
					>
						<p class="pop_desc">
              {{  $language.common.mount_utility_card_question }}
							<span>{{  $language.common.mount_utility_card_question_02 }}</span>
						</p>
						<slot name="content"></slot>
					</div>

					<div class="btn_wrap">
						<button class="pop_btn btn_fill_gray" @click="$emit('cancel')">{{  $language.common.cancel }}</button>
						<button
							v-if="is_use"
							class="pop_btn btn_fill_blue" @click="$emit('click', utility)"
						>{{  $language.common.cancellation }}</button>
						<button
							v-else
							class="pop_btn btn_fill_blue" @click="$emit('click', utility)"
						>{{  $language.common.mounting }}</button>
					</div>
				</article>
			</div>
		</div>
		<div class="bg_overlay"></div>
	</div>
</template>

<script>

export default {
	name: 'mafia0193'
	, props: ['utility']
	, data: function(){
		return {

		}
	}
	, computed: {
		is_use: function () {

			let t = false
			if (this.utility.utilty_card_mount_fg == 'Y') {
				t = true
			}
			return t
		}
	}
	, methods: {

	}
	,created() {
		console.log('created 0193')
		console.log(this.utility)
	}
}

</script>
