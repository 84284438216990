<template>
	<div class="section_wrap pt-135 pb-80">
		<div class="container">
			<div class="row">
				<section>
					<div class="pt-10">
						<h3 class="hide">{{  $language.nft.maincard }}</h3>
						<div
							class="main_card_wrap"
						>
							<button
								v-if="item_main_nft.mafi_nft_card_number"
								class="main_card"
								@click="toDetail(item_main_nft)"
							>
								<figure>
									<div class="o_square">
										<div class="inner">
											<div class="frame img">
												<img
													:src="$request.upload_url(item_main_nft.mafi_nft_card_img_url)"
													:alt="item_main_nft.mafi_nft_card_name"
												/>
											</div>
										</div>
									</div>
								</figure>
								<div class="card_badge">
									<span class="badge_40" :class="'badge_40_' + main_nft_badge"><em class="hide">music</em></span>
									<span class="badge_40 badge_40_capo hide"><em class="hide">capo</em></span>
								</div>
								<div class="card_name">
									<span>{{ item_main_nft.cartl_nft_name ? item_main_nft.cartl_nft_name : item_main_nft.mafi_nft_card_name }}</span>
								</div>
							</button>

							<button
								v-else
								class="main_card"
							>
								<figure>
									<div class="o_square">
										<div class="inner">
											<div class="frame img_none">
												<img :src="require('@/assets/image/@main_none.png')" alt="empty card" class="img_none">
											</div>
										</div>
									</div>
								</figure>
								<div class="card_badge" style="display: none;">
									<span class="badge_40 badge_40_music"><em class="hide">music</em></span>
								</div>
								<div class="card_name" style="display: none;">
									<span>{{  $language.nft.osolremio }}</span>
								</div>
							</button>
						</div>
					</div>
					<div
						v-if="false"
						class="pt-30"
					>
						<h3 class="sub_tit">UTILITIES CARD</h3>
						<div class="item_slot">

							<div
								v-for="num in 3"
								:key="'utility_' + num"
								class="item_card square"
							>
								<div class="inner">
									<button
										v-if="items_utility[num - 1]"
										@click="to0192(items_utility[num - 1].utilty_nft_card_number)"
									>
										<img
											:src="$request.upload_url(items_utility[num - 1].utilty_nft_card_img_url)"
											:alt="items_utility[num - 1].name">
									</button>
									<button
										v-else
										@click="on0192"
									></button>
								</div>
							</div>
						</div>
					</div>
					<div class="justify-space-between gap-10">
						<button class="btn_l btn_hist"  @click="on023">
							<span class="deposit">{{  $language.nft.nft_deposit }}</span>
						</button>
						<button class="btn_l btn_hist" @click="is_021 = !is_021">
							<span class="nft">NFT {{  $language.common.history }}</span>
						</button>
						<button class="btn_l btn_hist" @click="toSwap">
							<span class="swap">{{  $language.add.swap }}</span>
						</button>
					</div>
				</section>

				<section>
					<div class="pt-30">
						<div class="union_card_collect">
							<ul>
								<li
									v-for="(item, index) in items_card_list.union"
									:key="'item_' + index"
									class="union_square"
									@click="toDetail(item)"
								>
									<div class="union_inner">
										<div class="union_frame">
											<img :src="$request.upload_url(item.nft_card_img_url)" :alt="item.name">
											<span
												class="badge_30 union_badge"
												:class="'badge_30_' + item.badge"
											><em class="hide"> {{ item.badge }}</em></span>

											<div
												v-if="item.stakg_fg == 'Y'"
												class="lock"
											><span class="hide">{{  $language.nft.staking_card }}</span></div>
										</div>
									</div>
								</li>
								<li
									v-for="em in is_count.union"
									:key="'none_' + em"
									class="union_square"
								>
									<div class="union_inner">
										<div class=" img_none">
										</div>
									</div>
								</li>
							</ul>
						</div>
					</div>

					<hr
						v-if="items.length > 0 && items_nft.length > 0"
						class="mt-20" style="border: 1px solid rgba(255, 255, 255, 0.1); "
					/>
					<div
						v-else-if="items.length == 0 && items_nft.length == 0"
						style="text-align: center; color: white"
					>
						<img :src="require('@/assets/image/intro_character3_none.png')" style="width: 100px"/>
						<p style="font-family: open sans; font-weight: 400; font-size: 1.4rem; color: white; margin-top: 14px">{{  $language.nft.nft_not_taken }}<p/>
					</div>

					<div class="nft_card_collect mt-20">
						<ul>
							<li
								v-for="(item, index) in items_card_list.nft"
								:key="'item_' + index"
								class="square"
								@click="toDetail(item)"
							>
								<div class="inner">
									<div class="frame">
										<img
											v-if="item.cartl_nft_img_url"
											:src="$request.upload_url(item.cartl_nft_img_url)" :alt="item.cartl_nft_catgy_name"
										>
										<img
											v-else
											src="@/assets/image/main_card2.png"
											/>
										<span
											class="badge_30 union_badge"
											:class="'badge_30_' + item.badge"
										><em class="hide"> {{ item.badge }}</em></span>

										<div
											v-if="item.stakg_fg != 'Y'"
											class="lock"
										><span class="hide">{{  $language.nft.staking_card }}</span></div>
									</div>
								</div>
							</li>
							<li
								v-for="em in is_count.nft"
								:key="'none_' + em"
								class="square"
							>
								<div class="inner">
									<div class="frame img_none">
									</div>
								</div>
							</li>
						</ul>
					</div>
					<button class="btn_l btn_hist"  @click="on023">
						<span class="deposit">{{  $language.common.external_nft_wallet }}</span>
					</button>
				</section>

				<section
					v-if="false"
				>
					<div class="pt-30 pb-30">
						<div class="banner_nft_buy">
							<h3>{{ $language.greetings.greeting_03 }}</h3>
							<p>{{ $language.greetings.greeting_04 }}</p>
							<a @click="$emit('to', { name: 'drops', params: {type: 'nft'}})" class="btn_s btn_fill_red btn_banner_nft_buy">{{ $language.greetings.greeting_05 }}</a>
						</div>
					</div>
				</section>
			</div>
		</div>

		<mafia021
			v-if="is_021"
			:is_dual="true"
			:items="items"
			@to="is_021 = !is_021; is_022 = !is_022"
			@click="is_021 = false"
		></mafia021>

		<mafia022
			v-if="is_022"
			:is_dual="true"
			@to="is_022 = !is_022; is_021 = !is_021"
			@click="is_022 = false"
		></mafia022>

		<mafia023
			v-if="is_023"
			:wallet="wallet"
			@click="is_023 = false"
		></mafia023>

		<mafia0193
			v-if="is_0193"
			:utility="item_utility"

			@cancel="is_0193 = false"
		>

		</mafia0193>

		<mafia029
			v-if="is_029"
			:nft="item_main_nft"
			:Axios="Axios"
			:sample="sample"
			:user="user"

			@cancel="is_029 = false"
			@click="getData"
		></mafia029>

		<Popup_confirm
			v-if="is_popup"

			@click="$emit('to', { name: 'wallet'})"
			@cancel="is_popup = false"
		>
			<template v-slot:title>{{  $language.nft.nft_deposit }}</template>
			<template v-slot:main-txt>{{  $language.nft.no_wallet }}</template>
			<template v-slot:sub-txt>{{  $language.nft.nft_wallet_require }}</template>
			<template v-slot:name_confirm>{{  $language.nft.nft_wallet_create }}</template>
		</Popup_confirm>

	</div>
</template>

<script>

import Mafia021 from "@/view/Nft/mafia021";
import Mafia022 from "@/view/Nft/mafia022";
import Mafia023 from "@/view/Nft/mafia023";
import Mafia029 from "@/view/Nft/mafia029";
import Mafia0193 from "@/view/Nft/mafia019-3";
import Popup_confirm from "@/view/Layout/PopupConfirm";

export default {
	name: 'mafia018'
	, components: {Popup_confirm, Mafia021, Mafia022, Mafia023, Mafia029, Mafia0193}
	, props: ['Axios', 'sample', 'user']
	, data: function(){
		return {
			program: {
				name: 'NFT'
			}
			, is_021: false
			, is_022: false
			, is_023: false
			, is_029: false
			, is_0193: false
			, is_popup: false
			, items: []
			, items_nft: []
			, items_utility: []
			, item_main_nft: {}
			, item_utility: {}
			, wallet: {}
			, is_on_swap: false
		}

	}
	, computed: {
		is_count: function(){

			let t = {
				union: 0
				, nft: 0
			}
			t.union = this.items_card_list.union.length

			if(t.union == 0){
				t.union = 0
			}else{
				t.union = (t.union % 3) == 0 ? 0 : 3 - t.union % 3
			}


			t.nft = this.items_card_list.nft.length

			if(t.nft == 0){
				t.nft = 0
			}else{
				t.nft = (t.nft % 3) == 0 ? 0 : 3 - t.nft % 3
			}

			return t
		}
		, main_nft_badge: function(){
			let t = ''

			switch (this.item_main_nft.mafi_nft_card_div_code){
				case 'NF00100001':
					t = 'music'
					break;
				case 'NF00100002':
					t = 'art'
					break;
				case 'NF00100003':
					t = 'fashion'
					break;
				case 'NF00100004':
					t = 'influence'
					break;

			}

			return t
		}
		, items_card_list: function(){

			let t = {
				nft: []
				, union: []
			}

			this.items.filter(function(item){
				if(item.nft_card_div_code == 'NF00100004') {
					item.badge = 'utilities'
					item.card_type = 'utility'
					t.nft.push(item)
				}else if(item.nft_card_div_code == 'NF00100005') {
					item.badge = 'union'
					item.card_type = 'union'
					t.union.push(item)
				}else{
					item.card_type = 'nft'
					switch (item.nft_card_grade_code){
						case 'NF00200021':
							item.badge = 'music'
							break;
						case 'NF00200022':
							item.badge = 'art'
							break;
						case 'NF00200023':
							item.badge = 'fashion'
							break;
						case 'NF00200024':
							item.badge = 'influence'
							break;
					}
					t.nft.push(item)
				}

				return item
			})

			this.items_nft.filter(function(item){
				item.card_type = 'nft'
				item.badge = (item.cartl_nft_catgy_name).toLowerCase()

				t.nft.push(item)

				return item
			})

			return t

		}
	}
	,methods: {

		getData:  async function(){
			console.log('mafia018 getdata')
			try{
				this.$bus.$emit('on', true)
				const result = await this.$Request({
					method: 'post'
					,url: this.$api_url.api_path.get_main_nft
					,data: {
						member_number: this.user.member_number
					}
					, type: true
				})

				if(result.success){

					this.item_main_nft = result.data.mafi_card_info
					switch (this.item_main_nft.mafi_nft_card_div_code){
						case 'NF00100004':
							this.$set(this.item_main_nft, 'card_type', 'utility')
							break;
						case 'NF00100005':
							this.$set(this.item_main_nft, 'card_type', 'union')
							if(!this.item_main_nft.cartl_nft_number){
								this.$set(this.item_main_nft, 'nft_card_number', this.item_main_nft.mafi_nft_card_number)
							}
							break;
						default:
							this.$set(this.item_main_nft, 'card_type', 'nft')
							break;
					}
					this.items_utility = result.data.utilty_card_list
					this.items = result.data.holding_mafi_card_list
					this.items_nft = result.data.holding_cartl_nft_list
				}else{
					throw result.message
				}
			}catch(e){
				this.$bus.$emit('notify',  { type: 'error', e})
				console.log(e)
			}finally {
				this.$bus.$emit('on', false)
			}
		}
		,getUtilityList: async function(){
			try{

				const result = await this.$Request({
					method: 'post'
					,url: this.$api_url.api_path.get_utility_list
					,data: {
						member_number: this.user.member_number
						, page_number: this.$language.base.page_number
						, pagerecnum: this.$language.base.pagerecnum
					}
					, type: true
				})

				if(result.success){
					this.items_utility = result.data
				}else{
					throw result.message
				}
			}catch(e){
				this.$bus.$emit('notify',  { type: 'error', message: e})
				console.log(e)
			}finally {
				this.$bus.$emit('on', false)
			}
		}

		, to0192: function(num){
			this.$emit('to', {name: 'mafia0192', params: {card_type: 'utility', id: num}})
		}
		, on0192: function(){
			this.is_029 = !this.is_029
		}
		, on023: async function(){
			try{
				//this.$bus.$emit('on', true)
				const result = await this.$Request({
					method: 'post'
					, url: this.$api_url.api_path.get_wallet_address
					, data: {
						member_number: this.user.member_number
						, blockchain_div_code: 'BC00100001'
					}
					, type:true
				})
				if(result.success){

					this.wallet = result.data
					if(this.wallet.wallet_address){
						this.is_023 = true
					}else{
						throw this.$language.nft.not_found_wallet_address
					}
				}else{
					throw result.message
				}
			}catch (e) {
				console.log(e)
				this.$bus.$emit('notify', { type: 'error', message: e})
			}finally {
				this.$bus.$emit('on', false)
			}
		}
		, toDetail: function(item){
			console.log('item.card_type', item)
			if(item.card_type == 'nft'){
				this.$emit('to', { name: 'mafia019', params: {card_type: item.card_type, id: item.cartl_nft_number }})
			}else if(item.card_type == 'union') {
				this.$emit('to', { name: 'mafia0191', params: {card_type: item.card_type, id: item.nft_card_number }})
			}else{
				this.$emit('to', { name: 'mafia019', params: {card_type: item.card_type, id: item.nft_card_number }})
			}
		}
		, toSwap: function(){
			this.$bus.$emit('to', { name: 'NftSwap'})
		}
	}
	,async created() {
		this.$emit('onLoad', this.program)
		await this.getData()
		//await this.getMainNft()
		//await this.getUtilityList()
	}
}
</script>

<style>
	.union_card_collect li {
		width: calc(33.333% - 1rem);
		display: inline-block;
		margin: 0.5rem;
	}
	.union_card_collect li .union_frame{
		position: relative;
		border-radius: 1.2rem;
		border: 1px solid var(--line-white03);
		box-sizing: border-box;
		overflow: hidden;
	}
	.union_card_collect li .union_frame img {
		width: 100%
	}
	.union_badge {
		position: absolute; left: 10px; top: 10px;
	}

	.main_card .card_name > span {
		background-color: #171C61; opacity: 0.75; border: none
	}
</style>