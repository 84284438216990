<template>
	<div class="half_layer_wrap">
		<div class="half_layer_wrap_inner">
			<article class="half_view">
				<div class="layer_title">
					<h3>UTILITY CARD</h3>
				</div>
				<div class="utility_card">
					<div class="utility_card_area">
						<ul
							v-if="items_utility_card.length > 0"
						>
							<li
								v-for="(item, index) in items_utility_card"
								:key="'item_' + index"
								class="square"
							>
								<div class="inner">
									<button
										@click="on0193(item)"
									>
										<img
											:src="$request.upload_url(item.utilty_nft_card_img_url)"
											:alt="item.utilty_nft_card_name">
										<!-- TODO : 아이템 장착카드시 표시 -->
										<span
											v-if="item.utilty_card_mount_fg == 'Y'"
											class="item_choice"
										><em class="hide">{{  $language.common.mounting_item }}</em></span>
									</button>
								</div>
							</li>
						</ul>
						<div
							v-else
							class="list_none"
							style="overflow: hidden"
						>
							<img :src="require('@/assets/image/list_none.png')" alt="Not Found History">
							<span>{{  $language.nft.utility_not_taken }}</span>
						</div>
					</div>
				</div>

			</article>
			<button class="btn_close" @click="$emit('cancel')">
				<i class="icon icon-close"></i>
			</button>
		</div>

		<mafia0193
			v-if="is_0193"
			:utility="item_utility"

			@cancel="is_0193 = false"
			@click="do0193"
		></mafia0193>
	</div>
</template>

<script>

	import mafia0193 from "@/view/Nft/mafia019-3";
	export default {
		name: 'mafia029'
		, props: ['Axios', 'sample', 'nft', 'user']
		, components: {mafia0193}
		, data: function(){
			return {
				program:{
					name: this.$language.common.utility_card_list
				}
				, items_utility_card: []
				, is_0193: false
				, item_utility: {

				}
			}
		}
		, methods: {
			getData: async function(){
				try{
					this.$bus.$emit('on', true)
					const result = await this.$Request({
						method: 'post'
						,url: this.$api_url.api_path.get_utility_list
						,data: {

							member_number: this.user.member_number
							, page_number: this.$language.base.page_number
							, pagerecnum: this.$language.base.pagerecnum
						}
						, type: true
					})

					if(result.success){
						this.items_utility_card = result.data.utilty_nft_card_list
					}else{
						this.$bus.$emit('notify',  { type: 'error', message: result.message})
					}
				}catch(e){
					this.$bus.$emit('notify',  { type: 'error', message: this.$language.common.error})
					console.log(e)
				}finally {
					this.$bus.$emit('on', false)
				}
			}
			, on0193: function(item){
				console.log('on0193')
				this.item_utility = item
				this.is_0193 = true
				console.log(this.item_utility)
			}
			, do0193: async function(item){
				try{
					this.$bus.$emit('on', true)
					const result = await this.$Request({
						method: 'post'
						, url: this.$api_url.api_path.post_utility_flag
						, data: {
							member_number: this.user.member_number
							, utilty_nft_card_number: item.utilty_nft_card_number
							, reg_release_fg: item.utilty_card_mount_fg == 'Y' ? 'N' : 'Y'
						}
						, type: true
					})

					if(result.success){
						this.$emit('click')
						await this.getData()
					}else{
						throw result.message
					}

				}catch(e){
					console.log(e)
					this.$bus.$emit('notify',  { type: 'error', message: e})
				}finally {

					this.$bus.$emit('on', false)
					this.is_0193 = false
				}
			}
		}
		, created() {
			console.log(this.items_utility_card)
			this.getData()
		}
	}
</script>