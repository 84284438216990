<template>
	<div class="full_layer_wrap">
		<div class="full_layer_container">
			<div class="full_layer_wrap_inner">
				<article class="full_view">
					<div class="nft_card nft_qr">
						<h3 class="pop_tit">MAFIA {{  $language.nft.nft_deposit }}</h3>
						<p class="qr_tit">MAFIA NFT CARD</p>
						<figure
							v-if="wallet"
							class="nft_card_qr_area"
						>
							<qr-code :text="wallet.wallet_address" :size="200"></qr-code>
							<figcaption>
								<p>{{ wallet.wallet_address | tokenAddress(6, 6)}}</p>
								<button class="btn_copy" @click="copy"><span class="hide">{{  $language.common.copy_link }}</span></button>
							</figcaption>
						</figure>
						<div
							v-else
							style="font-size: 16px; padding: 40px 0"
						>
							{{  $language.nft.no_wallet }}
						</div>
					</div>
					<div class="btn_wrap">
						<button class="pop_btn btn_fill_blue" @click="$emit('click')">{{  $language.common.ok }}</button>
					</div>
				</article>
			</div>
		</div>
		<div class="bg_overlay"></div>
	</div>
</template>

<script>

	import Vue from 'vue'
	import VueQRCodeComponent from 'vue-qrcode-component'
	Vue.component('qr-code', VueQRCodeComponent)

	export default {
		name: 'mafia023'
		, props: ['wallet']
		, data: function(){
			return {
			}
		}
		, methods: {
			clipBoard: function (val){
				const t = document.createElement("textarea");
				document.body.appendChild(t);
				t.value = val;
				t.select();
				document.execCommand('copy');
				document.body.removeChild(t);
			}

			,copy: function (){
				this.clipBoard(this.wallet.wallet_address);
				this.$bus.$emit('notify',  { type: 'success', message: this.$language.nft.copied_wallet_address})
			}
		}
	}

</script>